// RemoteConfigMonitor.jsx
import React, { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Redirect from '../container/Redirect.js';

import { BindEmail } from '../resource/authEntryType.js';
import { getSentry } from '../resource/sentry.js';
import usePreviousValue from '../hook/usePreviousValue.js';
import defaults from '../resource/defaults.js';
import {
  mixpanelStartSessionRecording,
  mixpanelStopSessionRecording,
} from '../resource/mixpanel.js';

const isServer = typeof window === 'undefined';

export const RemoteConfigMonitor = ({
  hasToken = false,
  isRegisterJustCompleted = false,
  isLoginPhoneModalOpen = false,
  isLoginPhoneVerifyModalOpen = false,
  isAgeVerificationOptionsModalOpen = false,
  isLoginJustCompleted = false,
  isConfigureRemoteConfigMerged = false,
  isPresenceClientRemoteConfigMerged = false,
  isPresenceUserRemoteConfigMerged = false,
  isInNeedVerifiedCountries = false,
  isOnJoin = false,
  hasReferralCode = false,
  onloadRedirectUrl = '',
  shouldAgeVerify = false,
  shouldOpenBindEmailModal = false,
  shouldOpenBindPhoneNumberModal = false,
  shouldOpenAgeVerificationNotification = false,
  openAgeVerificationNotification = defaults.NULL_FUNCTION,
  closeAgeVerificationNotification = defaults.NULL_FUNCTION,
  redirectRecord = defaults.EMPTY_OBJECT,
  updateRedirectRecord = defaults.NULL_FUNCTION,
  openBindEmailModal = defaults.NULL_FUNCTION,
  bindPhone = defaults.NULL_FUNCTION,
  sentryReplaySessionSampleRate = -1,
  sentryReplayOnErrorSampleRate = -1,
  mixpanelRecordSessionsPercent = 1,
}) => {
  const hasRedirected = useRef(false);
  const prevSentryReplaySessionSampleRate = usePreviousValue(
    sentryReplaySessionSampleRate
  );
  const prevSentryReplayOnErrorSampleRate = usePreviousValue(
    sentryReplayOnErrorSampleRate
  );

  const isAgeVerificationModalsOpen =
    isLoginPhoneModalOpen ||
    isLoginPhoneVerifyModalOpen ||
    isAgeVerificationOptionsModalOpen;
  const prevIsAgeVerificationModalsOpen = usePreviousValue(
    isAgeVerificationModalsOpen
  );

  // redirect user by remote config, ex: redirect with utm_content
  const { pathname } = useLocation();
  const redirectTarget = useMemo(() => {
    try {
      if (!onloadRedirectUrl) {
        return null;
      }

      const url = new URL(onloadRedirectUrl, location.origin);
      const shouldRedirect =
        url.pathname !== pathname &&
        ((isServer && isConfigureRemoteConfigMerged) ||
          (hasToken
            ? isPresenceUserRemoteConfigMerged
            : isPresenceClientRemoteConfigMerged));
      if (!shouldRedirect) {
        return null;
      }

      let redirectUrl = onloadRedirectUrl;
      if (url.hostname === 'swag.live') redirectUrl = url.pathname;

      if (redirectRecord[redirectUrl]) return null;

      updateRedirectRecord({ [redirectUrl]: true });
      return redirectUrl;
    } catch (error) {
      return null;
    }
  }, [
    hasToken,
    isConfigureRemoteConfigMerged,
    isPresenceClientRemoteConfigMerged,
    isPresenceUserRemoteConfigMerged,
    onloadRedirectUrl,
    pathname,
    redirectRecord,
    updateRedirectRecord,
  ]);

  const shouldCallBindPhone = useMemo(() => {
    return (
      shouldAgeVerify &&
      !isOnJoin &&
      isInNeedVerifiedCountries &&
      isRegisterJustCompleted &&
      isPresenceUserRemoteConfigMerged &&
      shouldOpenBindPhoneNumberModal &&
      !hasReferralCode
    );
  }, [
    shouldAgeVerify,
    isOnJoin,
    isInNeedVerifiedCountries,
    isPresenceUserRemoteConfigMerged,
    isRegisterJustCompleted,
    shouldOpenBindPhoneNumberModal,
    hasReferralCode,
  ]);

  useEffect(() => {
    if (
      isRegisterJustCompleted &&
      isPresenceUserRemoteConfigMerged &&
      shouldOpenBindEmailModal &&
      // No need to verify age
      (!shouldCallBindPhone ||
        // Leaving age verification flow
        (prevIsAgeVerificationModalsOpen && !isAgeVerificationModalsOpen))
    ) {
      openBindEmailModal({
        entry: BindEmail.REGISTER,
        canClose: true,
      });
    }
  }, [
    isPresenceUserRemoteConfigMerged,
    isRegisterJustCompleted,
    shouldOpenBindEmailModal,
    shouldCallBindPhone,
    isAgeVerificationModalsOpen,
    prevIsAgeVerificationModalsOpen,
    openBindEmailModal,
  ]);

  useEffect(() => {
    if (shouldCallBindPhone) {
      bindPhone();
    }
  }, [shouldCallBindPhone, isLoginJustCompleted, bindPhone]);

  useEffect(() => {
    if (
      !hasToken ||
      !isInNeedVerifiedCountries ||
      !isPresenceUserRemoteConfigMerged ||
      !shouldOpenAgeVerificationNotification ||
      !shouldAgeVerify
    ) {
      closeAgeVerificationNotification();
      return;
    }

    openAgeVerificationNotification();
  }, [
    hasToken,
    isInNeedVerifiedCountries,
    isPresenceUserRemoteConfigMerged,
    shouldOpenAgeVerificationNotification,
    shouldAgeVerify,
    openAgeVerificationNotification,
    closeAgeVerificationNotification,
  ]);

  useEffect(() => {
    const shouldStartOrStopSentryReplay = hasToken
      ? isPresenceUserRemoteConfigMerged
      : isPresenceClientRemoteConfigMerged;

    if (shouldStartOrStopSentryReplay) {
      const startOrStopSentryReplay = async () => {
        try {
          const sentry = await getSentry();

          const isOnErrorSampleRateChanged =
            prevSentryReplayOnErrorSampleRate &&
            prevSentryReplayOnErrorSampleRate !== sentryReplayOnErrorSampleRate;

          const isSessionSampleRateChanged =
            prevSentryReplaySessionSampleRate &&
            prevSentryReplaySessionSampleRate !== sentryReplaySessionSampleRate;

          if (isOnErrorSampleRateChanged || isSessionSampleRateChanged) {
            const getReplay = async () => {
              let replay = sentry.getClient().getIntegrationByName('Replay');

              if (!replay) {
                const { replayIntegration } = await import('@sentry/browser');
                sentry.addIntegration(replayIntegration());
                replay = sentry.getClient().getIntegrationByName('Replay');
              }
              return replay;
            };

            if (
              isOnErrorSampleRateChanged &&
              sentryReplayOnErrorSampleRate !== 0
            ) {
              const replay = await getReplay();
              // set false so we can start replay without error
              replay._replay.recordingMode = false;
              replay.start();
              replay._replay.recordingMode = 'buffer';
              replay._replay.session.sampled = 'buffer';
              replay._replay._options.errorSampleRate =
                sentryReplayOnErrorSampleRate;
            }

            if (
              isSessionSampleRateChanged &&
              sentryReplaySessionSampleRate === 1
            ) {
              const replay = await getReplay();
              replay._replay.recordingMode = false;
              replay.start();
              // the session sampled value could be false | 'session' | 'buffer'
              // hack the value to session so that it won't affect by the sample rate
              // and can send the sentry replay event
              replay._replay.recordingMode = 'session';
              replay._replay.session.sampled = 'session';
            }

            if (
              (isOnErrorSampleRateChanged || isSessionSampleRateChanged) &&
              sentryReplaySessionSampleRate === 0 &&
              sentryReplayOnErrorSampleRate === 0
            ) {
              const replay = await getReplay();
              await replay._replay.stop();
            }
          }

          // eslint-disable-next-line no-empty
        } catch (_) {}
      };

      startOrStopSentryReplay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasToken,
    isPresenceClientRemoteConfigMerged,
    isPresenceUserRemoteConfigMerged,
    sentryReplaySessionSampleRate,
    sentryReplayOnErrorSampleRate,
  ]);

  // mixpanel replay
  useEffect(() => {
    if (mixpanelRecordSessionsPercent === 100) mixpanelStartSessionRecording();
    else if (mixpanelRecordSessionsPercent === 0)
      mixpanelStopSessionRecording();
  }, [mixpanelRecordSessionsPercent]);

  if (redirectTarget && !hasRedirected.current) {
    // to prevent infinite loop on client side
    hasRedirected.current = true;
    return <Redirect to={redirectTarget} status={302} />;
  }
  return null;
};

RemoteConfigMonitor.propTypes = {
  hasToken: PropTypes.bool,
  isRegisterJustCompleted: PropTypes.bool,
  isLoginPhoneModalOpen: PropTypes.bool,
  isLoginPhoneVerifyModalOpen: PropTypes.bool,
  isAgeVerificationOptionsModalOpen: PropTypes.bool,
  isLoginJustCompleted: PropTypes.bool,
  isConfigureRemoteConfigMerged: PropTypes.bool,
  isPresenceClientRemoteConfigMerged: PropTypes.bool,
  isPresenceUserRemoteConfigMerged: PropTypes.bool,
  isInNeedVerifiedCountries: PropTypes.bool,
  isOnJoin: PropTypes.bool,
  hasReferralCode: PropTypes.bool,
  onloadRedirectUrl: PropTypes.string,
  shouldAgeVerify: PropTypes.bool,
  shouldOpenBindEmailModal: PropTypes.bool,
  shouldOpenBindPhoneNumberModal: PropTypes.bool,
  shouldOpenAgeVerificationNotification: PropTypes.bool,
  openAgeVerificationNotification: PropTypes.func,
  closeAgeVerificationNotification: PropTypes.func,
  redirectRecord: PropTypes.object,
  updateRedirectRecord: PropTypes.func,
  openBindEmailModal: PropTypes.func,
  bindPhone: PropTypes.func,
  sentryReplaySessionSampleRate: PropTypes.number,
  sentryReplayOnErrorSampleRate: PropTypes.number,
  mixpanelRecordSessionsPercent: PropTypes.number,
};

export default RemoteConfigMonitor;
