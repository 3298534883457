// RemoteConfigMonitor.js
'use strict';
import { connect } from 'react-redux';

import RemoteConfigMonitor from '../component/RemoteConfigMonitor.jsx';
import getMeData from '../selector/getMeData.js';
import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';
import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import {
  REGISTER_OPTIONAL_AGE_VERIFY_NOTIFICATION,
  REGISTER_OPTIONAL_BIND_EMAIL_MODAL,
  REGISTER_OPTIONAL_BIND_PHONE_NUMBER_MODAL,
  PREVIEW_PRESET,
  ONLOAD_REDIRECT_URL,
  SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE,
  NEED_LANDING_COUNTRIES,
  MIXPANEL_RECORD_SESSIONS_PERCENT,
} from '../RemoteConfigKeys.js';
import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
  CONFIG_PRIORITY_PRESENCE_USER,
} from '../resource/configPriority.js';
import { login as loginModal } from '../resource/modalId.js';
import getIsMatchedPathname from '../resource/getIsMatchedPathname.js';
import getModalData from '../selector/getModalData.js';
import addPinnedNotification from '../action/addPinnedNotification.js';
import removePinnedNotification from '../action/removePinnedNotification.js';
import mergeMeData from '../action/mergeMeData.js';
import openBindEmailModal from '../action/openBindEmailModal.js';
import openBindPhoneModal from '../action/openBindPhoneModal.js';

const mapStateToProps = (state, { location: { pathname } }) => {
  const userCountry = getMeData(state, 'country');
  const accountLinks = getMeData(state, 'accountLinks');
  const verifiedEmail = accountLinks['email'];
  const needLandingCountries =
    getRemoteConfigData(state, NEED_LANDING_COUNTRIES) || [];
  const modalDisplayStatuses = [
    'display',
    'coming',
    'backing',
    'opening',
    'comingFromBottom',
  ];

  return {
    hasToken: !!getMeData(state, 'token'),
    isRegisterJustCompleted: getOperationData(
      state,
      ['register'],
      'isRegisterJustCompleted'
    ),
    isLoginPhoneModalOpen: !!modalDisplayStatuses.includes(
      getModalData(state, loginModal.PHONE, 'status')
    ),
    isLoginPhoneVerifyModalOpen: !!modalDisplayStatuses.includes(
      getModalData(state, loginModal.PHONE_VERIFY, 'status')
    ),
    isAgeVerificationOptionsModalOpen: !!modalDisplayStatuses.includes(
      getModalData(state, loginModal.AGE_VERIFICATION_OPTIONS, 'status')
    ),
    isConfigureRemoteConfigMerged: getIsRemoteConfigMerged(
      state,
      CONFIG_PRIORITY_CONFIGURE
    ),
    isPresenceClientRemoteConfigMerged: getIsRemoteConfigMerged(
      state,
      CONFIG_PRIORITY_PRESENCE_CLIENT
    ),
    isPresenceUserRemoteConfigMerged: getIsRemoteConfigMerged(
      state,
      CONFIG_PRIORITY_PRESENCE_USER
    ),
    isOnJoin: getIsMatchedPathname({
      pathname,
      dataKey: 'isOnJoin',
    }),
    onloadRedirectUrl: getRemoteConfigData(state, ONLOAD_REDIRECT_URL),
    shouldOpenAgeVerificationNotification: !!getRemoteConfigData(
      state,
      REGISTER_OPTIONAL_AGE_VERIFY_NOTIFICATION
    ),
    shouldOpenBindEmailModal:
      !verifiedEmail?.id &&
      !!getRemoteConfigData(state, REGISTER_OPTIONAL_BIND_EMAIL_MODAL),
    shouldOpenBindPhoneNumberModal: !!getRemoteConfigData(
      state,
      REGISTER_OPTIONAL_BIND_PHONE_NUMBER_MODAL
    ),
    sentryReplaySessionSampleRate: getRemoteConfigData(
      state,
      SENTRY_REPLAY_SESSION_SAMPLE_RATE
    ),
    sentryReplayOnErrorSampleRate: getRemoteConfigData(
      state,
      SENTRY_REPLAY_ON_ERROR_SAMPLE_RATE
    ),
    shouldAgeVerify: getRemoteConfigData(state, PREVIEW_PRESET) === 'blurred',
    redirectRecord: getMeData(state, 'redirect'),
    isInNeedVerifiedCountries: needLandingCountries.includes(userCountry),
    mixpanelRecordSessionsPercent: getRemoteConfigData(
      state,
      MIXPANEL_RECORD_SESSIONS_PERCENT
    ),
    hasReferralCode:
      getOperationData(state, ['acquisition', 'manual'], 'code') ||
      getOperationData(state, ['acquisition', 'referral'], 'code'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openAgeVerificationNotification: () => {
      dispatch(
        addPinnedNotification({
          componentName: 'AgeVerificationPinnedNotification',
        })
      );
    },
    closeAgeVerificationNotification: () => {
      dispatch(
        removePinnedNotification({
          componentName: 'AgeVerificationPinnedNotification',
        })
      );
    },
    updateRedirectRecord: value =>
      dispatch(mergeMeData({ field: 'redirect', value })),
    openBindEmailModal: payload => dispatch(openBindEmailModal(payload)),
    bindPhone: () => dispatch(openBindPhoneModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoteConfigMonitor);
